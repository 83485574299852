<template>
  <div>
    <b-row class="form-box">
      <b-col
        :md="item.md || 6"
        class="my-1"
        v-for="(item, index) in initData.information"
        :key="index"
      >
        <b-form-group
          v-if="item.show != false"
          :label="item.text"
          :id="item.id"
          :label-for="'form-' + index"
          class="search-flex"
          v-bind:class="[{ required: item.required }, item.class]"
          label-cols-sm="4"
          label-cols-lg="3"
        >
          <!-- input -->
          <template v-if="item.type == 'input'">
            <b-input-group :class="{ no_r: !item.icon }">
              <template v-slot:append>
                <b-input-group-text v-if="item.icon">
                  <b-icon :icon="item.icon"></b-icon>
                </b-input-group-text>
              </template>

              <b-form-input
                v-model="item.model"
                :type="item.input_type || 'text'"
                :placeholder="
                  item.disabled
                    ? ''
                    : item.placeholder || $t('page.please_choose_input')
                "
                :disabled="item.disabled"
              ></b-form-input>
            </b-input-group>
          </template>
          <!-- 纯文字 -->
          <template v-else-if="item.type == 'text'">
            <div :class="item.text_class">
              {{ item.display_text }}
            </div>
          </template>
          <!-- 下拉框 -->
          <template v-else-if="item.type == 'select'">
            <SelectLoading v-if="item.options == 0" :size="0.7"></SelectLoading>
            <sv-select
              v-model="item.model"
              :disabled="item.options == 0 || item.disabled"
              :placeholder="
                item.disabled
                  ? ''
                  : item.placeholder || $t('page.please_choose')
              "
              :options="item.options"
              :reduce="options => options.value"
              :multiple="item.multiple"
              :selectable="options => !options.disabled"
              label="label"
            >
              <template slot="option" slot-scope="option" v-if="option.index">
                <div class="d-center" :class="'level_' + option.index">
                  <span v-if="option.index == 2">--</span>
                  {{ option.label }}
                </div>
              </template>
            </sv-select>
          </template>
          <!-- 时间组件-->
          <template v-else-if="item.type == 'date'">
            <b-input-group>
              <template v-slot:append>
                <div
                  v-if="
                    !item.disabled && item.model != null && item.model != ''
                  "
                  class="datepicker-x"
                  @click="initialTime(item, 'model')"
                >
                  <b-icon-x font-scale="1.45"></b-icon-x>
                </div>
                <b-input-group-text>
                  <b-icon-calendar2-date></b-icon-calendar2-date>
                </b-input-group-text>
              </template>
              <b-form-datepicker
                v-model="item.model"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric'
                }"
                :hide-header="true"
                :placeholder="item.disabled ? '' : item.placeholder"
                :disabled="item.disabled"
                locale="de"
              ></b-form-datepicker>
            </b-input-group>
          </template>
          <!-- {{ item }} -->
        </b-form-group>
      </b-col>
    </b-row>
    <ArticleInformation
      :initData="initData.article_information"
      :formError="formError"
    ></ArticleInformation>
    <ReviewerStatus
      :initData="initData.reviewer_status"
      :options="options"
    ></ReviewerStatus>
    <Purchaser :initData="initData.purchaser"></Purchaser>
    <!--  <PurchaseOverview :initData="initData"></PurchaseOverview> -->
  </div>

  <!-- <Others></Others> -->
</template>

<script>
import SelectLoading from '@/components/Loading/Index';
// import MerchantPartner from '@/views/content/Detail/Information/MerchantPartner';
// import Others from '@/views/content/Detail/Information/Others';
// import apiBus from '@/common/apiBus/index';
import ArticleInformation from '@/views/content/Detail/ArticleReview/Information/ArticleInformation';
import ReviewerStatus from '@/views/content/Detail/ArticleReview/Information/ReviewerStatus';
import Purchaser from '@/views/content/Detail/ArticleReview/Information/Purchaser';

export default {
  name: 'ArticleReviewInformation',

  data() {
    return {
      id: null
      // classObject:{}
    };
  },
  props: {
    initData: Object,
    options: Object,
    formError: Boolean
  },
  inheritAttrs: false,
  components: {
    ArticleInformation,
    ReviewerStatus,
    Purchaser,
    // PurchaseOverview

    SelectLoading
    // MerchantPartner
    // Others
  },
  methods: {
    init() {
      // this.initData();
    },
    initialTime(obj, name) {
      console.log(obj, name);
      obj[name] = null;
    }
  },

  computed: {
    classObject() {
      return {};
    }
  },
  mounted() {
    this.init();
  }
};
</script>
<style scoped></style>
