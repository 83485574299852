var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"form-box"},_vm._l((_vm.initData.information),function(item,index){return _c('b-col',{key:index,staticClass:"my-1",attrs:{"md":item.md || 6}},[(item.show != false)?_c('b-form-group',{staticClass:"search-flex",class:[{ required: item.required }, item.class],attrs:{"label":item.text,"id":item.id,"label-for":'form-' + index,"label-cols-sm":"4","label-cols-lg":"3"}},[(item.type == 'input')?[_c('b-input-group',{class:{ no_r: !item.icon },scopedSlots:_vm._u([{key:"append",fn:function(){return [(item.icon)?_c('b-input-group-text',[_c('b-icon',{attrs:{"icon":item.icon}})],1):_vm._e()]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":item.input_type || 'text',"placeholder":item.disabled
                  ? ''
                  : item.placeholder || _vm.$t('page.please_choose_input'),"disabled":item.disabled},model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}})],1)]:(item.type == 'text')?[_c('div',{class:item.text_class},[_vm._v(" "+_vm._s(item.display_text)+" ")])]:(item.type == 'select')?[(item.options == 0)?_c('SelectLoading',{attrs:{"size":0.7}}):_vm._e(),_c('sv-select',{attrs:{"disabled":item.options == 0 || item.disabled,"placeholder":item.disabled
                ? ''
                : item.placeholder || _vm.$t('page.please_choose'),"options":item.options,"reduce":function (options) { return options.value; },"multiple":item.multiple,"selectable":function (options) { return !options.disabled; },"label":"label"},scopedSlots:_vm._u([{key:"option",fn:function(option){return (option.index)?[_c('div',{staticClass:"d-center",class:'level_' + option.index},[(option.index == 2)?_c('span',[_vm._v("--")]):_vm._e(),_vm._v(" "+_vm._s(option.label)+" ")])]:undefined}}],null,true),model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}})]:(item.type == 'date')?[_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [(
                  !item.disabled && item.model != null && item.model != ''
                )?_c('div',{staticClass:"datepicker-x",on:{"click":function($event){return _vm.initialTime(item, 'model')}}},[_c('b-icon-x',{attrs:{"font-scale":"1.45"}})],1):_vm._e(),_c('b-input-group-text',[_c('b-icon-calendar2-date')],1)]},proxy:true}],null,true)},[_c('b-form-datepicker',{attrs:{"date-format-options":{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
              },"hide-header":true,"placeholder":item.disabled ? '' : item.placeholder,"disabled":item.disabled,"locale":"de"},model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}})],1)]:_vm._e()],2):_vm._e()],1)}),1),_c('ArticleInformation',{attrs:{"initData":_vm.initData.article_information,"formError":_vm.formError}}),_c('ReviewerStatus',{attrs:{"initData":_vm.initData.reviewer_status,"options":_vm.options}}),_c('Purchaser',{attrs:{"initData":_vm.initData.purchaser}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }