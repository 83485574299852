var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"form-box"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-nav',{staticClass:"form-section",attrs:{"tabs":""}},[_c('b-nav-item',{attrs:{"disabled":""}},[_vm._v("Reviewer & Last Modification")])],1)],1),_vm._l((_vm.initData),function(item,index){return _c('b-col',{key:index,class:{
        'my-1': item.type != null && item.text != null
      },attrs:{"md":item.md || 6}},[(item.type == null && item.text == null)?_vm._l((item),function(x,y){return _c('b-row',{key:y},[_c('div',{staticClass:"my-1 col"},[_c('b-form-group',{staticClass:"search-flex",class:[{ required: x.required }, x.class],attrs:{"label":x.text,"id":x.id,"label-for":'form-' + index + y,"label-cols-sm":"4","label-cols-lg":"3"}},[(x.type == 'input')?[_c('b-input-group',{class:{ no_r: !x.icon },scopedSlots:_vm._u([{key:"append",fn:function(){return [(x.icon)?_c('b-input-group-text',[_c('b-icon',{attrs:{"icon":x.icon}})],1):_vm._e()]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":x.input_type || 'text',"min":x.min,"max":x.max,"placeholder":x.disabled
                        ? ''
                        : x.placeholder || _vm.$t('page.please_choose_input'),"disabled":x.disabled},model:{value:(x.model),callback:function ($$v) {_vm.$set(x, "model", $$v)},expression:"x.model"}})],1)]:_vm._e(),(x.type == 'img')?[_c('div',[(x.url != null && x.url != '')?_c('img',{staticClass:"image pointer",attrs:{"src":x.url,"alt":"image"},on:{"click":function($event){return _vm.showImg(x.url)}}}):_c('img',{staticClass:"image pointer",attrs:{"alt":"edit","src":require("@/assets/media/users/default.png")}})])]:_vm._e()],2)],1)])}):(item.show != false && item.type != null)?_c('b-form-group',{staticClass:"search-flex",class:[{ required: item.required }, item.class],attrs:{"label":item.text,"id":item.id,"label-for":'form-' + index,"label-cols-sm":"4","label-cols-lg":"3"}},[(item.type == 'input')?[_c('b-input-group',{class:{ no_r: !item.icon },scopedSlots:_vm._u([{key:"append",fn:function(){return [(item.icon || item.icon_text || item.icon_color)?_c('b-input-group-text',[(item.icon)?_c('b-icon',{attrs:{"icon":item.icon}}):_vm._e(),_vm._v(" "+_vm._s(item.icon_text)+" "),(item.icon_color && item.icon_color.indexOf('#') >= 0)?_c('div',{staticClass:"color-box",style:('background:' + item.icon_color)}):_vm._e(),(item.icon_color && item.icon_color.indexOf('#') < 0)?_c('div',{staticClass:"color-box",style:(("background:url(" + (item.icon_color) + ");background-size: 100%;"))}):_vm._e()],1):_vm._e()]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":item.input_type || 'text',"min":item.min,"max":item.max,"placeholder":item.disabled
                  ? ''
                  : item.placeholder || _vm.$t('page.please_choose_input'),"disabled":item.disabled},model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}})],1)]:(item.type == 'select')?[(item.options == 0)?_c('SelectLoading',{attrs:{"size":0.7}}):_vm._e(),_c('sv-select',{attrs:{"disabled":item.options == 0 || item.disabled,"placeholder":item.disabled
                ? ''
                : item.placeholder || _vm.$t('page.please_choose'),"options":item.options,"reduce":function (options) { return options.value; },"multiple":item.multiple,"selectable":function (options) { return !options.selectable; },"label":"label"},scopedSlots:_vm._u([{key:"option",fn:function(option){return (option.index)?[_c('div',{staticClass:"d-center",class:'level_' + option.index},[(option.index == 2)?_c('span',[_vm._v("--")]):_vm._e(),_vm._v(" "+_vm._s(option.label)+" ")])]:undefined}}],null,true),model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}})]:_vm._e(),(item.type == 'textarea')?[_c('b-form-textarea',{attrs:{"id":item.id,"rows":item.rows,"placeholder":item.disabled
                ? ''
                : item.placeholder || _vm.$t('page.please_choose_textarea'),"disabled":item.disabled},model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}})]:_vm._e()],2):_vm._e()],2)})],2),_c('b-modal',{ref:"modal",attrs:{"centered":"","no-close-on-backdrop":"","hide-footer":"","scrollable":"","title":"Image","id":"image-box","size":"lg"},model:{value:(_vm.imageModal),callback:function ($$v) {_vm.imageModal=$$v},expression:"imageModal"}},[(_vm.showImgUrl != null)?_c('img',{attrs:{"src":_vm.showImgUrl,"alt":""}}):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }